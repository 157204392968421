// project import
import Routes from './routes/index';
import ThemeCustomization from './themes/index';
import ScrollTop from './components/ScrollTop';




// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (

  <ThemeCustomization>
    
    <ScrollTop>
    
      <Routes />

    </ScrollTop>
  </ThemeCustomization>
  
);

export default App;
