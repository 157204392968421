// assets
import { DashboardOutlined, RetweetOutlined,FontSizeOutlined } from '@ant-design/icons';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import FeaturedOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';


// icons
const icons = {
  DashboardOutlined,
  RetweetOutlined,
  BookmarkOutlinedIcon,
  FontSizeOutlined,
  FeaturedOutlinedIcon,
  ArrowLeftOutlinedIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const storedTheme = localStorage.getItem('currentTheme');


const dashboardsec1 = {
  id: 'group-dashboard',
  type: 'collapse',
  type: 'group',
  title: 'Dashboard',
  icon: icons.ApiOutlined,
  children: [
    {
      id: 'section1',
      title: 'Section1',
      type: 'collapse',
      icon: icons. ArrowLeftOutlinedIcon,
      // url: `/mainlayout/${storedTheme}/dashboard`,
      children: [
        {
          id: 'dashboard',
          title: 'Add Games',
          type: 'item',
          url: `/mainlayout/${storedTheme}/dashboard`,
          icon: icons.DashboardOutlined,
          breadcrumbs: false,
        
        },
        {
          id: 'rearrange',
          title: 'Rearrange',
          type: 'item',
          url: `/mainlayout/${storedTheme}/Rearrange`,
          icon: icons.RetweetOutlined,
          breadcrumbs: false,
         
        },
        {
          id: 'title',
          title: 'Website Title',
          type: 'item',
          url: `/mainlayout/${storedTheme}/webTitle`,
          icon: icons.FontSizeOutlined,
          breadcrumbs: false
        },
        // {
        //   id: 'Heading',
        //   title: 'Website Description',
        //   type: 'item',
        //   url: `/mainlayout/${storedTheme}/Heading`,
        //   icon: icons.BookmarkOutlinedIcon,
        //   breadcrumbs: false
        // },
        {
          id: 'Features',
          title: ' Website Features',
          type: 'item',
          url: `/mainlayout/${storedTheme}/Features`,
          icon: icons.FeaturedOutlinedIcon,
          breadcrumbs: false
        },
        
      ]
    }
  ]
  
};
const dashboardsec2 = {
  id: 'group-dashboard',
  type: 'collapse',
  type: 'group',
  icon: icons.ApiOutlined,
  children: [
    {
      id: 'section2',
      title: 'Section2',
      type: 'collapse',
      icon: icons. ArrowLeftOutlinedIcon,
      children: [
        {
          id: 'section2',
          title: 'Add Games',
          type: 'item',
          url: `/mainlayout/${storedTheme}/Section2`,
          icon: icons.DashboardOutlined,
          breadcrumbs: false,
          
          
        },
        {
          id: 'rearrange2',
          title: 'Rearrange',
          type: 'item',
          url: `/mainlayout/${storedTheme}/RearrangeSection2`,
          icon: icons.RetweetOutlined,
          breadcrumbs: false
        },
        {
          id: 'title',
          title: 'Website Title',
          type: 'item',
          url: `/mainlayout/${storedTheme}/TitleSection2`,
          icon: icons.FontSizeOutlined,
          breadcrumbs: false
        },
        {
          id: 'Heading',
          title: 'Website Description',
          type: 'item',
          url: `/mainlayout/${storedTheme}/DescriptionSection2`,
          icon: icons.BookmarkOutlinedIcon,
          breadcrumbs: false
        }
      ]
    }
  ]
  
};




export {dashboardsec1, dashboardsec2};
