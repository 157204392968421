import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout/index';
// import { Route, Routes } from 'react-router-dom';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard')));
const RearrangePage = Loadable(lazy(() => import('../pages/Rearrange')));
const Home = Loadable(lazy(() => import('../pages/Home')));
const Loginpage = Loadable(lazy(() => import('../pages/AdminLogin')));
const SEOpage = Loadable(lazy(() => import('../pages/SEO')));
const Titlepage = Loadable(lazy(() => import('../pages/WebsiteTitle')));
const Logopage = Loadable(lazy(() => import('../pages/WebLogo')));
const Headingpage = Loadable(lazy(() => import('../pages/Heading')));
const Featurespage = Loadable(lazy(() => import('../pages/Features')));
const Pluginpage = Loadable(lazy(() => import('../pages/Plugins')));
const Footertext = Loadable(lazy(() => import('../pages/Footertext')));
const Policypage = Loadable(lazy(() => import('../pages/Policypage')));
const CustomeTheme = Loadable(lazy(() => import('../pages/CustomTheme')));
const Buttoncolorpage = Loadable(lazy(() => import('../pages/Buttoncolor')));
const PolicyFront = Loadable(lazy(() => import('../pages/Home/PolicyPage')));
const Section2Page = Loadable(lazy(()=> import('../pages/AddGameSection2')));
const Section2Rearrange = Loadable(lazy(()=> import('../pages/AddGameSection2/Rearrange2')));
const Sec2Title = Loadable(lazy(()=> import('../pages/AddGameSection2/Sec2Title')));
const Sec2Description = Loadable(lazy(()=> import('../pages/AddGameSection2/Sec2Description')));
const ThemestablePage = Loadable(lazy(()=> import('../pages/ThemesList')));
const SuperAdminDashpage = Loadable(lazy(()=> import('../pages/SuperAdmindashboard')))
const AdduserPage = Loadable(lazy(()=> import('../pages/SuperAdmindashboard/AddUser')));
const CreateTemp = Loadable(lazy(()=> import('../pages/SuperAdmindashboard/CreateTemplate')));
const AddTemp = Loadable(lazy(()=> import('../pages/SuperAdmindashboard/CreateTemplate/AddTemplate')));
const Adminfrontend = Loadable(lazy(()=> import('../pages/SuperAdmindashboard/AdminFrontend')));
const SelectTheme = Loadable(lazy(()=> import('../pages/SelectTheme')));





// ==============================|| MAIN ROUTING ||============================== //

const AdminDashboard = {
  path:'/mainlayout',
  element:<MainLayout/>,
  children:[
    {
      path: 'Admindashboard',
      element:<SuperAdminDashpage/>
    },
    {
      path: 'createUser',
      element:<AdduserPage/>
    },
    {
      path: 'createTemplate/:Template',
      element:<CreateTemp/>
    },
    {
      path:'AddTemplate',
      element: <AddTemp/>
    },
  ]
}
const MainRoutes = {
  path: '/mainlayout/:Themes',
  element: <MainLayout />,
  children: [
    {
      path: 'dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'Rearrange',
      element: <RearrangePage />
    },
    {
      path: 'seo',
      element: <SEOpage />
    },
    {
      path: 'webtitle',
      element: <Titlepage />
    },
    {
      path: 'Logo',
      element: <Logopage />
    },
    {
      path: 'Heading',
      element: <Headingpage />
    },
    {
      path: 'Features',
      element: <Featurespage />
    },
    {
      path: 'plugin',
      element: <Pluginpage />
    },
    {
      path: 'Footer',
      element: <Footertext />
    },
    {
      path: 'Policy',
      element: <Policypage />
    },
    {
      path: 'Theme',
      element: <CustomeTheme />
    },
    {
      path: 'ButtonColor',
      element: <Buttoncolorpage />
    },
    {
      path: 'Policy',
      element: <PolicyFront />
    },
    {
      path: 'Section2',
      element: <Section2Page/>
    },
    {
      path: 'RearrangeSection2',
      element: <Section2Rearrange/>
    },
    {
      path: 'TitleSection2',
      element: <Sec2Title/>
    },
    {
      path: 'DescriptionSection2',
      element: <Sec2Description/>
    },
    {
      path: 'selectTheme',
      element : <SelectTheme/>
    }

  ]
};

const AdminTemplate ={
  path: 'Template/:Template',
  element: <Adminfrontend/>
}


const Main = {
  path: '/:Themes/:Template',
  element: <Home />
};

const Login = {
  path: '/',
  element: <Loginpage />
};
const ThemesTable = {
  path: '/Themes',
  element: <ThemestablePage/>
}


export { MainRoutes, Main, Login, ThemesTable, AdminDashboard, AdminTemplate};
