// project import
// import pages from './pages';
import {dashboardsec1, dashboardsec2} from './dashboard';
import {SEO,webTitle} from './Other';

// ==============================|| MENU ITEMS ||============================== //


const menuItems = {
  items:[dashboardsec1,dashboardsec2, SEO, webTitle]
};


export default menuItems;
