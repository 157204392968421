// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import authSlice  from './Login';
import currentThemes from './Thems';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ 
    menu,
    authSlice, currentThemes
 });

export default reducers;
