// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  user: null, 
  isAuthenticated: false, 
};

// ==============================|| SLICE - AUTHENTICATION ||============================== //

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logoutSuccess(state) {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
});

export default authSlice.reducer;

export const { loginSuccess, logoutSuccess } = authSlice.actions;
