import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import { useParams } from 'react-router-dom';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const{Themes} = useParams();
  const userRole = localStorage.getItem("role");
  return (
    // only available in paid version
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack direction="row" spacing={1} alignItems="center">
        {userRole === 'superadmin'?(
           <h2 style={{textTransform:'uppercase', fontSize:'25px'}}>Dashboard</h2>
        ):( <h2 style={{textTransform:'uppercase', fontSize:'25px'}}>{Themes}</h2>)}
       
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
