import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
  Button
} from '@mui/material';

// project import
import MainCard from '../../../../../components/MainCard';
import Transitions from '../../../../../components/@extended/Transitions';
import ProfileTab from './ProfileTab';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';


// assets
// import avatar1 from 'assets/images/users/avatar-1.png';
import { LogoutOutlined } from '@ant-design/icons';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = (props) => {
  const theme = useTheme();
  const { Themes } = useParams();
  const handleLogout = async () => {
    // logout
  };
  const storedTheme = localStorage.getItem('currentTheme');
  const username= localStorage.getItem("name");
  const userRole = localStorage.getItem('role')
  const userid = localStorage.getItem('id');
  const anchorRef = useRef(null);
  const [Templatedata, setTemplatedata] = useState();
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const getTemplate = () => {
    axios
      .get('http://146.190.54.116/api/selectedTemp')
      .then((res) => {
       
        const filteredData = res.data.filter((item) => item.userid == userid && item.Themename == Themes);
        setTemplatedata(filteredData[0]);
        window.open(`/${storedTheme}/${filteredData[0]?.selectedTheme}`);
      })
      .catch((err) => console.log(err));
  };


  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleTheme =()=>{
    localStorage.removeItem('currentTheme');
  }

  const iconBackColorOpen = 'grey.300';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75, display: 'flex', alignItems: 'center', gap: '20px' }}>
      {userRole === 'superadmin' ? (
        ''
      ) : (
        <>
          <Link to={`/Themes`} target="_self">
            <Button className="view-button" variant="outlined" sx={{ cursor: 'pointer', fontSize: '15px', fontWeight: '600' }} onClick={()=>handleTheme()}>
              Themes List
            </Button>
          </Link>
          {/* <Link to={`/${storedTheme}`} target="_blank"> */}
            <Button
              className="view-button"
              variant="outlined"
              sx={{ cursor: 'pointer', fontSize: '15px', fontWeight: '600', color: 'green', border: '1px solid green' }}
              onClick={() => getTemplate()}
            >
              Preview Site
            </Button>
          {/* </Link> */}
        </>
      )}

      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar alt="profile user" sx={{ width: 32, height: 32 }} />
          <Typography variant="subtitle1">{userRole === 'superadmin' ?'Admin' : username}</Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            <Avatar alt="profile user" sx={{ width: 32, height: 32 }} />
                            <Stack>
                              <Typography variant="h6">Admin</Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <IconButton size="large" color="secondary" onClick={handleLogout}>
                            <LogoutOutlined />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {open && (
                      <>
                        <ProfileTab handleLogout={handleLogout} />
                      </>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
