// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  themes: null,  
};

// ==============================|| SLICE - AUTHENTICATION ||============================== //

const currentThemes = createSlice({
  name: 'currentThemes',
  initialState,
  reducers: {
    currentTheme(state, action) {
      state.themes = action.payload;
    }
  },
});

export default currentThemes.reducer;

export const { currentTheme } = currentThemes.actions;
